import * as React from 'react';
import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import './Event.css';
import { saveAttendance, isEventAdmin, parseToken, leadingZero } from './utils';
import Button from '@mui/material/Button';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField'
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { FormControl, SliderValueLabel } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import ImageCompress from 'quill-image-compress';


const EditEvent = () => {

    const [token, clientConfig, numberOfPresent, globalstarttime, loadNumberOfPresent, pushStartTime, globalSetStarttime, refreshEventNumber] = useOutletContext();
    const [username, setUsername] = useState();
    const navigate = useNavigate();
    const { state } = useLocation();
    const id = state?state.id:null;
    const [event, setEvent] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [description, setDescription] = useState("");
    const [initQuill, setInitQuill] = useState(true);
    const [startTimeError, setStartTimeError] = useState();
    const [starttime, setStarttime] = useState(null);
    const [endtime, setEndtime] = useState(null);

    const [title, setTitle] = useState();
    const [date, setDate] = useState(null);
    const [visibleDaysBefore, setVisibleDaysBefore] = useState(10);
    const [visibleDaysBeforeError, setVisibleDaysBeforeError] = useState();
    const [maxCapacity, setMaxCapacity] = useState(10);
    const [maxCapacityError, setMaxCapacityError] = useState();
    const [participantsPublic, setParticipantsPublic] = useState(false);
    const [dateError, setDateError] = useState();

    const showMaxCapError = () => {
        return maxCapacityError ? { error: "error" } : null;
    }

    const showVisibleDaysError = () => {
        return visibleDaysBeforeError ? { error: "error" } : null;
    }


    const { quill, quillRef, Quill } = useQuill({ modules: { imageCompress: true }});
    if (Quill && !quill) { 
        Quill.register('modules/imageCompress', ImageCompress);
    }

    useEffect(() => {

        if (id && event == null) {
            loadEvent();
        } else {
            setIsLoading(false);
        }

        if (quill) {
            if (initQuill) {
                if(event){
                    quill.root.innerHTML = event.description;
                }
                setInitQuill(false);
            }
            quill.on('text-change', (delta, oldDelta, source) => {
                setDescription(quill.root.innerHTML);
            })
        };
    }, [quill, event, isLoading]);

    const loadEvent = () => {
        console.log("Loading event für editing");

        fetch("api/event/" + id, {
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "GET",
        }).then(function (response) {
            response.json().then((result) => {
                console.log("Setting event");
                setEvent(result);
                setTitle(result.title);
                setDate(dayjs(result.startTimestamp));
                setStarttime(dayjs(result.startTimestamp));
                setMaxCapacity(result.maxCapacity);
                setVisibleDaysBefore(result.visibleDaysBefore);
                setParticipantsPublic(result.participantsPublic);
                
                if(result.endTimestamp){
                    setEndtime(dayjs(result.endTimestamp));
                }
                console.log("Setting isLoading to false");
                setIsLoading(false);

            });

        });
    }


    const editSeries = (clickEvent) => {
        navigate("/editSeriesEvent", {state:{id: event.seriesId}})
    }


    const handleSaveEvent = (clickEvent) => {

        console.log("Handling saving event");
        let e = false;

        if (date === null) {
            setDateError("Datum erforderlich");
            e = true;
        } else if (date != null && date) {
            setDateError(null);
        }

        if (starttime === null) {
            setStartTimeError("Startzeit erforderlich");
            e = true;
        } else if (starttime != null && startTimeError) {
            setStartTimeError(null);
        }

        if (date === null) {
            setDateError("Start Datum erforderlich");
            e = true;
        } else if (date != null && date) {
            setDateError(null);
        }

        let maxCapacityNumber = Number(maxCapacity);
        if (isNaN(maxCapacityNumber)) {
            e = true;
            setMaxCapacityError("Trag hier mal was ordentliches ein");
        } else if (maxCapacityError) {
            setMaxCapacityError(null);
        }

        let visibleDaysBeforeNumber = Number(visibleDaysBefore);
        if (isNaN(visibleDaysBeforeNumber)) {
            e = true;
            setVisibleDaysBeforeError("Trag hier mal was ordentliches ein");
        } else if (visibleDaysBeforeNumber) {
            setVisibleDaysBeforeError(null);
        }

        if (e) {
            return;
        }

        let startTimeStamp = null;
        let endTimeStamp = null;

        startTimeStamp = new Date(Date.parse(date.year()+ "-" + leadingZero((date.month() + 1)) + "-" + leadingZero(date.date()) + "T" + leadingZero(starttime.hour()) + ":" + leadingZero(starttime.minute()))).getTime();
        if (endtime && endtime!==""){
            endTimeStamp = new Date(Date.parse(date.year()+ "-" + leadingZero((date.month() + 1)) + "-" + leadingZero(date.date()) + "T" + leadingZero(endtime.hour()) + ":" + leadingZero(endtime.minute()))).getTime();
        }


        //Nachricht bauen
        let message = {
            "id": (event && event.id? event.id:null),
            "seriesId": (event && event.seriesId? event.seriesId:null),
            "title": title,
            "startTimestamp": startTimeStamp,
            "endTimestamp": endTimeStamp,
            "visibleDaysBefore": visibleDaysBeforeNumber,
            "maxCapacity": maxCapacityNumber,
            "participantsPublic": participantsPublic,
            "description": description
        };

          
        fetch("api/save_event",{
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(message)
        }).then(function (response) { 
            console.log(response);
            navigate("/eventlist") 
        });

    }

    const handleDeleteEvent = (clickEvent) => {
          
        console.log("Deleting event");

        fetch("api/delete_event/" + id, {
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "GET",
        }).then(function (response) {
            navigate("/eventlist") 
        }).catch(e => {
            console.log("Error: " + e.message);
        });;

    }

    return (

        <>
            {!isLoading &&
                <Stack spacing={2}>

                    {event && event.seriesId &&
                        <>
                            <Typography variant="h6" component="h1" style={{ marginTop: "20px", textAlign: "center" }}>
                                Achtung: Dieses Event ist Teil einer Serie.
                            </Typography>
                            <Button variant="contained"
                                onClick={editSeries}
                            >
                                Ganze Serie bearbeiten
                            </Button>
                        </>
                    }

                    <TextField
                        label="Titel"
                        variant="standard"
                        value={title}
                        onChange={(event) => setTitle(event.target.value)}
                    />

                    <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>
                        <DatePicker label="Event Datum"
                            value={date}
                            onChange={(newValue) => setDate(newValue)}
                            slotProps={{
                                textField: {
                                    error: !!dateError,
                                    helperText: dateError,
                                },
                            }}
                        />
                         <TimePicker
                            label="Beginn"
                            value={starttime}
                            onChange={(newValue) => setStarttime(newValue)}
                            slotProps={{
                                textField: {
                                    error: !!startTimeError,
                                    helperText: startTimeError,
                                },
                            }}
                        />
                        <TimePicker
                            label="Ende (Optional)"
                            value={endtime}
                            onChange={(newValue) => setEndtime(newValue)}
                        />
                    </LocalizationProvider>

{/*                     <Typography variant="h6" component="h2">
                        Verfügbare Plätze: {event.maxCapacity - event.currentAcceptance}
                    </Typography> */}

                    
                    <TextField
                        label="Tage vor Event anzeigen"
                        variant="standard"
                        value={visibleDaysBefore}
                        onChange={(event) => setVisibleDaysBefore(event.target.value)}
                        {...showVisibleDaysError()}
                    />
                    <TextField
                        label="Maximale Kapazität"
                        variant="standard"
                        value={maxCapacity}
                        onChange={(event) => setMaxCapacity(event.target.value)}
                        {...showMaxCapError()}
                    />

                <FormControl
                    orientation="horizontal"
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={participantsPublic}
                                    onChange={(event) => { setParticipantsPublic(event.target.checked) }}
                                />
                            }
                            label="Teilnehmer für alle sichtbar"
                        />
                    </Box>
                </FormControl>


                    <div>
                        <div ref={quillRef} />
                    </div>

{/*                     {event.participants &&
                        <Typography style={{ marginTop: "20px", textAlign: "center" }}>
                            Zugesagt:
                        </Typography>
                    } */}

                    <Button variant="contained"
                        onClick={handleSaveEvent}
                    >
                        Event speichern
                    </Button>

                    
                    {event && event.id &&
                        <Button variant="contained"
                            onClick={handleDeleteEvent}
                            style={{backgroundColor:"red"}}
                        >
                            Event löschen
                        </Button>
                    }


                </Stack>

            }
        </>
    );
}

export default EditEvent;