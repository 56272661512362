import * as React from 'react';
import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

const Current = () => {

    const [token, clientConfig, numberOfPresent, starttime, loadNumberOfPresent, pushStartTime, setStarttime] = useOutletContext();
    const [entries, setEntries] = useState([]);
    const [username, setUsername] = useState();

    const loadEntries = () => {
        console.log("Loading entries...");
        fetch("api/all_presence", {
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "GET",
        }).then(function (response) {
            response.json().then((result) => {
                console.log("Got the following result:" + JSON.stringify(result));
                setEntries(result);
            });

        });
    }

    

    useEffect(() => {
        console.log("Token in History: " + token);
        let tusername = JSON.parse(atob(JSON.parse(token).content)).username;
        console.log("Username in History: " + tusername);
        setUsername(tusername);

        loadEntries();
    }, [numberOfPresent]);

    const renderActions = (entry) => {

        if (entry.created_by === username) {
            return <CardActions>
                <IconButton color="primary"
                    onClick={() => {
                        fetch("/api/delete_presence", {
                            headers: {
                                "token": token,
                                "Content-Type": "application/json"
                            },
                            method: "POST"
                        }).then(function (response) {
                            loadEntries();
                            loadNumberOfPresent();

                        });
                    }}>
                    <DeleteIcon />
                </IconButton>
            </CardActions>

        } else {
            return "";
        }


    }


    const listItems = entries.map((entry) =>
        <Card>
            <CardContent>
                <Grid container spacing={0}>
                    <Grid item xs={4}>
                        <Typography variant="caption" component="h2">
                            Name:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" component="h2">
                            {entry.name}
                        </Typography>
                    </Grid>

                    <Grid item xs={4}>
                        <Typography variant="caption" component="h2">
                            Startzeit:
                        </Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="caption" component="h2">
                            {leadingZero(new Date(entry.start).getHours()) + ":" + leadingZero(new Date(entry.start).getMinutes()) }
                        </Typography>
                    </Grid>

                </Grid>

            </CardContent>
            {renderActions(entry)}
        </Card >
    );

    const style = {
        margin: 0,
        top: 'auto',
        right: 20,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
    };

    return (
        <>
            <Stack spacing={2}>
                {listItems}
            </Stack>
        </>

    );
}

const leadingZero = (input) => {
    return ('0'  + input).slice(-2)
}

export default Current;