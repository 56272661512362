export const parseToken = function (token) {
    if (token !== undefined && token !== null) {
        let t = JSON.parse(token);
        return JSON.parse(decodeBase64(t.content));
    } else {
        return null;
    }
}

const decodeBase64 = (input) => {
    let bytes = Uint8Array.from(atob(input), c => c.charCodeAt(0));
    return new TextDecoder().decode(bytes);
}

export const leadingZero = (input) => {
    return ('0' + input).slice(-2)
}


export const timestampToString = (timestamp) => {
    let d = new Date(timestamp);
    return leadingZero(d.getDate()) + "." + leadingZero((d.getMonth() + 1)) + "." + d.getFullYear() + " " + leadingZero(d.getHours()) + ":" + leadingZero(d.getMinutes());
}

export const loadEvents = (token, callback) => {
    console.log("Loading entries...");
    fetch("api/all_events", {
        headers: {
            "token": token,
            "Content-Type": "application/json"
        },
        method: "GET",
    }).then(function (response) {
        response.json().then((result) => {
            callback(result);
        });

    });
}


export const saveAttendance = (token, event, value, callback) => {
    console.log("Handling attendence status: " + JSON.stringify(value));

    let toSend = { id: event.id, attend: value };
    console.log("Sending: " + JSON.stringify(toSend));

    fetch("api/set_attendance", {
        headers: {
            "token": token,
            "Content-Type": "application/json"
        },
        method: "POST",
        body: JSON.stringify(toSend)

    }).then(function (response) {
        /*             response.json().then((result) => {
                    console.log("Setting event");
                    setEvent(result);s
                    console.log("Setting isLoading to false");
                    setIsLoading(false);
                }); */

        callback();
    });
}

export const isEventAdmin = (token, clientConfig) => {
    console.log("Checking if user is in event group");
    if (token) {
        let groups = token.groups;
        if (groups === undefined || groups.length === 0)
            return false;
        return groups.includes(clientConfig.event_group);
    } else {
        return false;
    }
}

export const resetLogin = () => {
    localStorage.removeItem("schiessbuch_token");
    window.location.reload();
}