import * as React from 'react';
import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import Stack from '@mui/material/Stack';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useNavigate } from "react-router-dom";
import { timestampToString, loadEvents, parseToken, isEventAdmin } from './utils';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { saveAttendance } from './utils';
import './EventList.css';
import VisibilityIcon from '@mui/icons-material/Visibility';


const EventList = () => {

    const [token, clientConfig, numberOfPresent, starttime, loadNumberOfPresent, pushStartTime, setStarttime, refreshEventNumber] = useOutletContext();
    const [events, setEvents] = useState([]);
    const [username, setUsername] = useState();
    const [parsedToken, setParsedToken] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        console.log("Token in Event list: " + token);
        let pToken = parseToken(token);
        setParsedToken(pToken);
        let tusername = pToken.username;
        console.log("Username in Event List: " + tusername);
        setUsername(tusername);
        refreshEventNumber();

        loadEvents(token, setEvents);
    }, []);

    const renderActions = (event) => {

        let checkIconColor = event.attending ? "#008a20" : "#b8e6bf";
        let closeIconColor = event.attending === false ? "#d63638" : "#facfd2";

        return <CardActions >

            <CheckIcon style={{ color: checkIconColor }} onClick={() => { saveAttendance(token, event, event.attending === true ? null : true, () => { loadEvents(token, setEvents); refreshEventNumber(); }) }} />
            <CloseIcon style={{ color: closeIconColor }} onClick={() => { saveAttendance(token, event, event.attending === false ? null : false, () => { loadEvents(token, setEvents); refreshEventNumber(); }) }} />

        </CardActions>


    }

    const showVisbility = (event) => {
        console.log("Show visibility: " + JSON.stringify(event)); 
        let currentEpoch = new Date().getTime();
        if (isEventAdmin(parsedToken, clientConfig) && currentEpoch>event.visibleTimestamp) {
            return <VisibilityIcon/>;
            
        } else {
            return null;
        }
    }

    const listItems = events.map((event) =>

        <Card key={event.id} className={event.attending === null ? 'alerts-border' : ''} style={{ overflow: "visible" }}>
            <CardContent>
                <Grid container >
                    <Grid item xs={12} container spacing={0} onClick={() => {
                        navigate("/event", { state: { id: event.id } });
                    }}>
                        <Grid item xs={11}>
                            <Typography variant="subtitle1" component="h1">
                                {event.title}
                            </Typography>
                        </Grid>
                        <Grid item xs={1}>
                            <Typography variant="subtitle1" component="h1">
                                {showVisbility(event)}
                            </Typography>
                        </Grid>

                        <Grid item xs={4}>
                            <Typography variant="caption" component="h2">
                                Startzeit:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption" component="h2">
                                {timestampToString(event.startTimestamp)}
                            </Typography>
                        </Grid>

                        {event.endTimestamp &&
                            <>
                                <Grid item xs={4}>
                                    <Typography variant="caption" component="h2">
                                        Endzeit:
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="caption" component="h2">
                                        {timestampToString(event.endTimestamp)}
                                    </Typography>
                                </Grid>
                            </>
                        }

                        <Grid item xs={4}>
                            <Typography variant="caption" component="h2">
                                Verfügbare Plätze:
                            </Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <Typography variant="caption" component="h2">
                                {event.maxCapacity - event.currentAcceptance}
                            </Typography>
                        </Grid>
                    </Grid>
                    {/*                     <Grid item xs={1}>
                    {
                        event.attending === true ? <CheckIcon style={{height:"100%", width: "100%"}}/>: (event.attending === false ? <CloseIcon style={{height:"100%", width: "100%"}}/>: <QuestionMarkIcon style={{height:"100%", width: "100%"}}/>)

                    }
                    </Grid> */}
                </Grid>

            </CardContent>
            {renderActions(event)}
        </Card >
    );



    return (
        <>
            <Stack spacing={2} style={{ marginBottom: "10px" }}>
                {listItems}
            </Stack >
        </>
    );
}

export default EventList;