import React, { useState, useEffect } from 'react';
import './App.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import History from "./pages/History";
import Current from "./pages/Current";
import EventList from "./pages/EventList";
import Event from "./pages/Event";
import EditSeriesEvent from "./pages/EditSeriesEvent";
import EditEvent from './pages/EditEvent';

function App() {
  let API_URL = "/";
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const requestCode = urlParams.get('code');

  const [isLoading, setIsLoading] = useState(true);
  const [token, setToken] = useState(undefined);
  const [clientConfig, setClientConfig] = useState(undefined);

  useEffect(() => {
    console.log("Found following url code parameter:", requestCode);

    if (clientConfig === undefined) {
      fetch(API_URL + "api/clientconfig")
        .then(function (response) { return response.json(); })
        .then(function (json) {
          console.log("Received following clientConfig: " + JSON.stringify(json));
          setClientConfig(json);
        })
    } else {
      checkAuthState();
    }
    ;
  }, [clientConfig]);

  const checkAuthState = () => {

    let currentToken = localStorage.getItem("schiessbuch_token"); 

    if(clientConfig.debug_token) {
      console.log("Setting current token to debug token: " + JSON.stringify(clientConfig.debug_token));
      currentToken = clientConfig.debug_token;
    }

    if (!currentToken) {
      if (requestCode !== null) {
        console.log("Getting auth with request code " + requestCode);
        fetch(API_URL + "api/auth?code=" + requestCode, {
          method: 'POST'
        })
          .then(function (response) { return response.json(); })
          .then(function (json) {
            console.log("Received following token: " + json);
            localStorage.setItem("schiessbuch_token", JSON.stringify(json));
            setToken(JSON.stringify(json));
            window.history.replaceState(null, '', window.location.pathname);
            console.log("setting isLoading to false");
            setIsLoading(false);
          });

      } else {
        console.log("No current token found. Redirecting to login page");
        console.log(clientConfig);
        let redirectUrl = clientConfig.nextcloud_url + "apps/oauth2/authorize?response_type=code&client_id=" + clientConfig.client_id;
        console.log("Redirecting to " + redirectUrl);
        window.location.href = redirectUrl;
      }
    } else {
      setToken(currentToken);
      console.log("Got token: " + currentToken);
      console.log("setting isLoading to false");
      setIsLoading(false);
    }
  }


  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout isLoading={isLoading} token={token} clientConfig={clientConfig}/>}>
          <Route index element={<Home />} />
          <Route path="/history" element={<History />} />
          <Route path="/current" element={<Current />} />
          <Route path="/editSeriesEvent" element={<EditSeriesEvent />} />
          <Route path="/editEvent" element={<EditEvent />} />
          <Route path="/eventlist" element={<EventList />} />
          <Route path="/event" element={<Event />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
