import * as React from 'react';
import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import { timestampToString } from './utils';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import { saveAttendance, isEventAdmin, parseToken } from './utils';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import './Event.css';

const Event = () => {

    const [token, clientConfig, numberOfPresent, starttime, loadNumberOfPresent, pushStartTime, setStarttime, refreshEventNumber] = useOutletContext();
    const [username, setUsername] = useState();
    const navigate = useNavigate();
    const { state } = useLocation();
    const { id } = state;
    const [event, setEvent] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [weatherData, setWeatherData] = useState(null);
    const [weatherIcon, setWeatherIcon] = useState(null);

    //const {eventid} = route.params;

    const loadEvent = (callback) => {
        console.log("Loading event");

        fetch("api/event/" + id, {
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "GET",
        }).then(function (response) {
            response.json().then((result) => {
                console.log("Setting event");
                setEvent(result);
                console.log("Setting isLoading to false");
                setIsLoading(false);
                if (callback) {
                    callback(result);
                }

            });

        }).catch(e => {
            console.log("Error: " + e.message);
        });;
    }

    const loadWeather = (result) => {
        let currentEpoch = new Date().getTime();
        
        if(result.startTimestamp < currentEpoch + 14*24*60*60*1000) {
            fetch("api/get_weather/" + Math.round(result.startTimestamp/1000), {
                headers: {
                    "token": token,
                    "Content-Type": "application/json"
                },
                method: "GET",
            }).then(function (response) {
                response.json().then((result) => {
                    console.log("Weather code: " + result);
                    setWeatherData(result);
                    setWeatherIcon("/weather/"+result.weatherCode+".png");
                })
                .catch(e => {
                    console.log("Error parsing weather data: " + e.message);
                });
    
            }).catch(e => {
                console.log("Error: " + e.message);
            });;
        }

    }

    const handleAttendanceStatus = (blubb, value) => {
        setIsUpdating(true);

        saveAttendance(token, event, value, () => {
            loadEvent(() => {
                setIsUpdating(false);
            });
            refreshEventNumber();
        });
    }

    useEffect(() => {
        console.log("Init Event mask");
        console.log("Token in History: " + token);
        let tusername = JSON.parse(atob(JSON.parse(token).content)).username;
        console.log("Username in History: " + tusername);
        console.log(id);
        setUsername(tusername);
        loadEvent(loadWeather);

    }, []);


    const renderParticipents = (event) => {
        let output = null;
        if (event.accepted && event.accepted.length > 0) {
            console.log("Got some participants");

            output = event.accepted.map((participant) => {
                let name = participant.name;
                return <Typography style={{ textAlign: "center" }}>
                    {name}
                </Typography>
            });

        }
        return output;
    }

    const renderDeclined = (event) => {
        let output = null;
        if (event.declined && event.declined.length > 0) {
            console.log("Got some participants");

            output = event.declined.map((participant) => {
                let name = participant.name;
                return <Typography style={{ textAlign: "center" }}>
                    {name}
                </Typography>
            });

        }
        return output;
    }

    const showEditButton = () => {
        console.log("Checking if user is event admin");

        if (isEventAdmin(parseToken(token), clientConfig)) {
            return <Button variant="contained"
                onClick={() => navigate("/editEvent", { state: { id: event.id } })}
            >
                Event bearbeiten
            </Button>
        } else {
            return null;
        }
    }

    const renderEndTime = function (event) {   
        if(event.endTimestamp){

            return <>
                <Typography variant="h6" component="h2">
                    Ende: {timestampToString(event.endTimestamp)}
                </Typography>
            </>;
    
        } else {
            return null;
        }

    }


    return (

        <>
            {!isLoading &&
                <Stack spacing={1}>
                    <Typography variant="h5" component="h1">
                        {event.title}
                    </Typography>

                    
                    <Grid container spacing={0}>
                        <Grid item xs={10}>
                            <Typography variant="h6" component="h2">
                                Start: {timestampToString(event.startTimestamp)}
                            </Typography>

                            {renderEndTime(event)}
                        </Grid>
                        <Grid item xs={2} style={{maxHeight:"40px", textAlign:"center"}}>
                            {weatherIcon && 
                            <>
                            <img
                                src={weatherIcon}
                                style={{maxHeight:"100%"}}
                                
                            />
                            <div style={{fontSize:"0.8rem"}}>
                                {weatherData.temperature2m}°C
                            </div>
                            <div style={{fontSize:"0.8rem"}}>
                                {weatherData.precipitation}l / {weatherData.precipitationProbability}%
                            </div>
                            
                            </>}
                        </Grid>
                    </Grid>
                

                    <Typography variant="h6" component="h2">
                        Verfügbare Plätze: {event.maxCapacity - event.currentAcceptance}
                    </Typography>

                    {event.description &&
                        <div class="ql-container ql-snow ql-editor" dangerouslySetInnerHTML={{ __html: event.description }} style={{ maxWidth: "100%", border: "none", margin:"0px", padding:"0px", marginTop: "1rem"}} />
                    }

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, position: 'relative'}}>
                        <ToggleButtonGroup
                            aria-labelledby="demo-error-radios"
                            name="quiz"
                            value={event.attending}
                            orientation="vertical"
                            onChange={handleAttendanceStatus}
                            exclusive
                            disabled={isUpdating}
                            style={{ width: "100%" }}
                        >
                            <ToggleButton value={true} style={{ backgroundColor: (event.attending !== null && event.attending === true) ? "#008a20" : "#b8e6bf" }}>Bin dabei </ToggleButton>
                            <ToggleButton value={false} style={{ backgroundColor: (event.attending !== null && event.attending === false) ? "#d63638" : "#facfd2" }}>Dieses mal nicht</ToggleButton>

                        </ToggleButtonGroup>

                        {isUpdating && (
                                <div style={{position: "absolute", width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center"}}>
                                    <CircularProgress
                                        size={40}
                                        sx={{
                                            color: "rgb(25, 118, 210)",
                                            position: "absolute",
                                            zIndex: 1,
                                        }}
                                    />
                                </div>
                        )}

                    </Box>

                    {event.participantsPublic &&
                        <Typography style={{ fontSize: "0.7rem", textAlign: "center" }}>
                            Deine Teilnahme wird allen Mitgliedern angezeigt
                        </Typography>
                    }

                    {event.accepted && event.accepted.length > 0 &&
                        <Typography style={{ marginTop: "20px", textAlign: "center" }}>
                            Zugesagt:
                        </Typography>
                    }
                    {renderParticipents(event)}

                    {event.declined && event.declined.length>0 && 
                        <Typography style={{ marginTop: "20px", textAlign: "center" }}>
                            Abgesagt:
                        </Typography>
                    }

                    {renderDeclined(event)}

                    {showEditButton()}

                </Stack>

            }
        </>
    );
}

export default Event;