import * as React from 'react';
import { useState, useEffect } from 'react';
import { useOutletContext } from "react-router-dom";
import Stack from '@mui/material/Stack';
import { useNavigate } from "react-router-dom";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import TextField from '@mui/material/TextField';
import { FormControl, SliderValueLabel } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import CancelIcon from '@mui/icons-material/Cancel';
import Chip from '@mui/material/Chip';
import FormHelperText from '@mui/material/FormHelperText';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { leadingZero } from './utils';
import { useQuill } from 'react-quilljs';
import 'quill/dist/quill.snow.css';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import Typography from '@mui/material/Typography';
import ImageCompress from 'quill-image-compress';

const EditSeriesEvent = () => {

    const [token] = useOutletContext();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [id, setId]= useState(null)
    const [startDate, setStartDate] = useState(null);
    const [startDateError, setStartDateError] = useState();
    const [endDate, setEndDate] = useState(null);
    const [endDateError, setEndDateError] = useState();
    const [starttime, setStarttime] = useState(null);
    const [startTimeError, setStartTimeError] = useState();
    const [endtime, setsetEndtime] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState(null);
    const [participantsPublic, setParticipantsPublic] = useState(false);
    const [weekdays, setWeekdays] = React.useState([]);
    const [weekdayError, setWeekdayError] = useState();
    const [visibleDaysBefore, setVisibleDaysBefore] = useState(10);
    const [visibleDaysBeforeError, setVisibleDaysBeforeError] = useState();
    const [maxCapacity, setMaxCapacity] = useState(10);
    const [maxCapacityError, setMaxCapacityError] = useState();
    const [loadedSeries, setLoadedSeries] = useState(null);

    const { quill, quillRef, Quill } = useQuill({ modules: { imageCompress: true }});
    if (Quill && !quill) { 
        Quill.register('modules/imageCompress', ImageCompress);
    }

    useEffect(() => {
        let local_id;
        if (state!=null && id==null) {
            local_id = state.id;
            setId(local_id);
        }
        console.log("Got series id: " + id);

        if(local_id!=null && loadedSeries==null){
            console.log("Init series with id: " + id);
            loadSeriesEvent(local_id);
        }

        if (quill) {
            quill.on('text-change', (delta, oldDelta, source) => {
                setDescription(quill.root.innerHTML);
            });
            if(loadedSeries!==null){
                quill.root.innerHTML = loadedSeries.description;
            }
        };
    }, [quill, loadedSeries]);

    const loadSeriesEvent = (seriesId) => {
        console.log("Loading series event für editing");

        fetch("api/series_event/" + seriesId, {
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "GET",
        }).then(function (response) {
            response.json().then((result) => {
                console.log("Setting up sereies-event");
                console.log(result);

                let start = 4884505821000;
                let end = 0;

                let weekdays = [];
           

                for (let e of result.events) {
                    if(!e.isDirty){
                        if(e.timestamp.start<start) {
                            start = e.timestamp.start;
                        }

                        if(e.timestamp.start>end) {
                            end = e.timestamp.start;
                        }

                        let weekday = dayjs(e.timestamp.start).day();
                        if (weekdays.indexOf(weekday)===-1){
                            weekdays.push(weekday)
                        }

                    }
                }

                setLoadedSeries(result);
                setStartDate(dayjs(start));
                setEndDate(dayjs(end));
                setStarttime(dayjs(start));
                setTitle(result.title);
                setMaxCapacity(result.maxCapacity);
                setVisibleDaysBefore(result.visibleDaysBefore);
                setDescription(result.description);
                setWeekdays(weekdays);
                setParticipantsPublic(result.participantsPublic);
            });

        });
    }

    const weekDaysSelection = [
        "Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag",
    ];

    const weekdaysValues = [
        0, 1, 2, 3, 4, 5, 6, 7
    ];

    const handleChange = (event) => {
        let {
            target: { value },
        } = event;
        console.log(value);
        value = value.sort(function (a, b) { return a - b; });
        console.log("Nach sort: " + value);
        setWeekdays(
            typeof value === 'string' ? value.split(',') : value,
        );
    };


    const handleRemoveWeekday = (e) => {
        const newWeekdays = weekdays.filter(nummer => nummer !== e);
        setWeekdays(newWeekdays);
    };



    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const handleSendButton = (event) => {
        let e = false;

        if (starttime === null) {
            setStartTimeError("Startzeit erforderlich");
            e = true;
        } else if (starttime != null && startTimeError) {
            setStartTimeError(null);
        }

        if (startDate === null) {
            setStartDateError("Start Datum erforderlich");
            e = true;
        } else if (startDate != null && startDateError) {
            setStartDateError(null);
        }

        if (endDate === null) {
            setEndDateError("Ende Datum erforderlich");
            e = true;
        } else if (endDate != null && endDateError) {
            setEndDateError(null);
        }

        if (weekdays.length === 0) {
            setWeekdayError("Mindestens einen Wochentag auswählen");
            e = true
        } else if (weekdays.length > 0 && weekdayError) {
            setWeekdayError(null);
        }

        let maxCapacityNumber = Number(maxCapacity);
        if (isNaN(maxCapacityNumber)) {
            e = true;
            setMaxCapacityError("Trag hier mal was ordentliches ein");
        } else if (maxCapacityError) {
            setMaxCapacityError(null);
        }

        let visibleDaysBeforeNumber = Number(visibleDaysBefore);
        if (isNaN(visibleDaysBeforeNumber)) {
            e = true;
            setVisibleDaysBeforeError("Trag hier mal was ordentliches ein");
        } else if (visibleDaysBeforeNumber) {
            setVisibleDaysBeforeError(null);
        }



        // Validate scheiben

        if (e) {
            return;
        }

        //Nachricht bauen

        console.log("Weekdays: "+ weekdays);

        //let startString = startDate.year()+ "-" + leadingZero((startDate.month() + 1)) + "-" + leadingZero(startDate.date()) + "T" + leadingZero(starttime.hour()) + ":" + leadingZero(starttime.minute());
        let startString=  startDate.year()+ "-" + leadingZero((startDate.month() + 1)) + "-" + leadingZero(startDate.date())+"T00:00";
        let endString = endDate.year()+ "-" + leadingZero((endDate.month() + 1)) + "-" + leadingZero(endDate.date())+"T00:00";;
        console.log(startString);

        let start = Date.parse(startString);
        let end = Date.parse(endString);

        let timestamps = [];

        for (var d = new Date(start); d <= new Date(end); d.setDate(d.getDate() + 1)) {
            let startTimeStamp = null;
            let endTimeStamp = null;
            if(weekdays.includes(d.getDay())) {

                let t = d.getYear()+ "-" + leadingZero((d.getMonth() + 1)) + "-" + leadingZero(d.getDate()) + "T" + leadingZero(starttime.hour()) + ":" + leadingZero(starttime.minute());
                startTimeStamp = new Date(Date.parse(d.getFullYear()+ "-" + leadingZero((d.getMonth() + 1)) + "-" + leadingZero(d.getDate()) + "T" + leadingZero(starttime.hour()) + ":" + leadingZero(starttime.minute())));
                if (endtime && endtime!==""){
                    endTimeStamp = new Date(Date.parse(d.getFullYear()+ "-" + leadingZero((d.getMonth() + 1)) + "-" + leadingZero(d.getDate()) + "T" + leadingZero(endtime.hour()) + ":" + leadingZero(endtime.minute())));
                }
                timestamps.push([startTimeStamp.getTime(),endTimeStamp?endTimeStamp.getTime():null]);
            }
        }



        let message = {
            "title": title,
            "timestamps": timestamps,
            "visibleDaysBefore": visibleDaysBeforeNumber,
            "maxCapacity": maxCapacityNumber,
            "participantsPublic": participantsPublic,
            "description": description
        };

        if(loadedSeries && loadedSeries.seriesId){
            message.seriesId = loadedSeries.seriesId;
        }

          
        fetch("api/save_series_event",{
            headers: {
                "token": token,
                "Content-Type": "application/json"
            },
            method: "POST",
            body: JSON.stringify(message)
        }).then(function (response) { 
            console.log(response);
            navigate("/eventlist") 
        });

    }

    const showMaxCapError = () => {
        return maxCapacityError ? { error: "error" } : null;
    }

    const showVisibleDaysError = () => {
        return visibleDaysBeforeError ? { error: "error" } : null;
    }

    const numberOfDirtyEvents = () => {
        return dirtyEvents().length;
    }

    const dirtyEvents = () => {
        if(loadedSeries){
            let dirtyEvents = loadedSeries.events.filter((event) => event.isDirty);
            return dirtyEvents;
        } else {
            return []
        }
    }

    const renderDirtyEvents = () => {
        let events = dirtyEvents();
        return events.map((event) => {
            let thisDate = new Date(event.timestamp.start);
            return <Button style={{marginTop:"0px"}} onClick={() => {
                navigate("/event", {state:{id: event.id}});
            }}>
                {thisDate.toLocaleDateString("de-DE")}
            </Button>
            
        })
    }

    return (

        <Stack spacing={2} style={{ marginBottom: "5px" }}>

            {numberOfDirtyEvents()>0 &&
                <Typography>
                    Dieses Serienevent enthält {numberOfDirtyEvents()} Termin(e), die einzeln bearbeitet wurden. Diese werden bei einer Aktualisierung nicht berücksichtigt.
                </Typography>
            }
            
            <TextField
                    label="Titel"
                    variant="standard"
                    value={title}
                    onChange={(event) => setTitle(event.target.value)}
                />

            <LocalizationProvider adapterLocale="de" dateAdapter={AdapterDayjs}>

                <DatePicker label="Startdatum der Serie"
                    value={startDate}
                    onChange={(newValue) => setStartDate(newValue)}
                    required
                    slotProps={{
                        textField: {
                            error: !!startDateError,
                            helperText: startDateError,
                        },
                    }}
                />

                <DatePicker label="Enddatum der Serie"
                    value={endDate}
                    onChange={(newValue) => setEndDate(newValue)}
                    required
                    slotProps={{
                        textField: {
                            error: !!endDateError,
                            helperText: endDateError,
                        },
                    }}
                />

                <TimePicker
                    label="Beginn Uhrzeit"
                    value={starttime}
                    onChange={(newValue) => setStarttime(newValue)}
                    slotProps={{
                        textField: {
                            error: !!startTimeError,
                            helperText: startTimeError,
                        },
                    }}
                />


                <TimePicker
                    label="Ende Uhrzeit (optional)"
                    value={endtime}
                    onChange={(newValue) => setsetEndtime(newValue)}
                />

                <FormControl error={weekdayError != null}>
                    <InputLabel id="demo-simple-select-label">Wochentage</InputLabel>
                    <Select
                        multiple
                        input={<OutlinedInput id="select-multiple-chip" label="Scheiben" />}
                        value={weekdays}
                        onChange={handleChange}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => (
                                    <Chip
                                        onDelete={() => handleRemoveWeekday(value)}
                                        key={value}
                                        label={weekDaysSelection[value]}
                                        deleteIcon={
                                            <CancelIcon
                                                onMouseDown={(event) => event.stopPropagation()}
                                            />
                                        }
                                    />
                                ))}
                            </Box>
                        )}
                        MenuProps={MenuProps}
                    >
                        {weekDaysSelection.map((name, index) => (
                            <MenuItem
                                key={weekdaysValues[index]}
                                value={weekdaysValues[index]}
                            >
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                    {weekdayError && <FormHelperText>Mindestens einen Wochentag auswählen</FormHelperText>}
                </FormControl>

                <TextField
                    label="Tage vor Event anzeigen"
                    variant="standard"
                    value={visibleDaysBefore}
                    onChange={(event) => setVisibleDaysBefore(event.target.value)}
                    {...showVisibleDaysError()}
                />

                <TextField
                    label="Maximale Kapazität"
                    variant="standard"
                    value={maxCapacity}
                    onChange={(event) => setMaxCapacity(event.target.value)}
                    {...showMaxCapError()}
                />


                <FormControl
                    orientation="horizontal"
                    sx={{ justifyContent: 'space-between' }}
                >
                    <Box>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={participantsPublic}
                                    onChange={(event) => { setParticipantsPublic(event.target.checked) }}
                                />
                            }
                            label="Teilnehmer für alle sichtbar"
                        />
                    </Box>
                </FormControl>

                <div>
                    <div ref={quillRef} />
                </div>

            </LocalizationProvider>


            {id && <Button variant="contained"
                onClick={handleSendButton}
            >
                Serien-Event aktualisieren
            </Button>}

            {!id && <Button variant="contained"
                onClick={handleSendButton}
            >
                Serien-Event anlegen
            </Button>}

            {numberOfDirtyEvents()>0 &&
                <Typography style={{textAlign:"center"}}>
                    Geänderte Serien-Elemente:
                </Typography>
            }
            {renderDirtyEvents()}
        </Stack>

    );
}


export default EditSeriesEvent;