import * as React from 'react';
import { Container, Stack, Box, Typography, CircularProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import Grid from '@mui/material/Grid2';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import Badge from '@mui/material/Badge';
import PeopleIcon from '@mui/icons-material/People';
import EventIcon from '@mui/icons-material/Event';
import WarningIcon from '@mui/icons-material/Warning';
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
import { parseToken, loadEvents, isEventAdmin, resetLogin } from './utils';



const Layout = (props) => {
  console.log("isLoadind: " + props.isLoading);

  const getStartTimeFromLocalStorage = () => {
    console.log("Refreshing start time");
    let startTime = localStorage.getItem("START_TIME");
    let epochTime = new Date(Number(startTime)).getTime();
    let currentEpoch = new Date().getTime();
    if (epochTime < (currentEpoch - 12 * 60 * 60 * 1000)) {
      console.log("Removing old start time");
      localStorage.removeItem("START_TIME");
      return null
    } else {
      return dayjs(new Date(Number(startTime)));
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [numberOfPresent, setNumberOfPresent] = React.useState(0);
  const [numberOfOpenEvents, setNumberOfOpenEvents] = React.useState(null);
  const [starttime, setStarttime] = React.useState(getStartTimeFromLocalStorage());
  const [token, setToken] = React.useState(parseToken(props.token));
  const [isNetworkError, setIsNetworkError] = React.useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  useEffect(() => {
    const timeoutId = setInterval(() => {
      loadNumberOfPresent();
    }, 
      2000 * 60
    );

    loadNumberOfPresent();
    console.log("Debug localStartTime: ", starttime);

    console.log("Layout: " + JSON.stringify(props));
    if (props.token !== undefined) {
      let token = parseToken(props.token);
      console.log("Layout / Setting token: " + token);
      setToken(token);
      refreshEventNumber();
    }

    return () => {
      clearInterval(timeoutId);
    }

  }, [props]);

  const refreshEventNumber = ()=> {
    if(props.token!=null) {
      console.log("Refreshing event number");
      loadEvents(props.token, (events) => {
        let counter = 0;
        for (let e of events) {
          if (e.attending===null){
            counter++;
          }
        }
        setNumberOfOpenEvents(counter);
      });
    }
  }

  const loadNumberOfPresent = () => {

    if (props.token) {
      console.log("Loading presence");
        fetch("api/count_present", {
          headers: {
            "token": props.token,
            "Content-Type": "application/json"
          },
          method: "GET",
        }).then(function (response) {
          if(response.status===401) {
            resetLogin();
          }
          response.json().then((result) => {
            console.log("Number of people present: " + result.counter);
            setIsNetworkError(false);
            setNumberOfPresent(result.counter);
          });
        }).catch(e => {
          setIsNetworkError(true);
          console.log("Error: " + e.message);
        });
      } 
    
  };

  const pushStartTime = (time) => {

    let current_millis = time.getTime();
    let message = {
      start: current_millis
    }
    console.log("Setting start time: " + time);
    fetch("api/set_presence", {
      headers: {
        "token": props.token,
        "Content-Type": "application/json"
      },
      method: "POST",
      body: JSON.stringify(message)
    }).then(function (response) {
      console.log("setStartTime resonse: " + response);
      localStorage.setItem("START_TIME", current_millis);
      setStarttime(dayjs(new Date(Number(current_millis))));
      loadNumberOfPresent();
    });
  }


  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  let output;

  if (props.isLoading) {
    output = <Container maxWidth="sm">
      <Stack spacing={2}>
        <Box sx={{ display: 'flex' }} justifyContent="center">
          <Typography variant="h6" gutterBottom>
            Loading....
          </Typography>
        </Box>
        <Box sx={{ display: 'flex' }} justifyContent="center">
          <CircularProgress />
        </Box>
      </Stack>
    </Container>;
  } else {
    output = <Outlet context={[props.token, props.clientConfig, numberOfPresent, starttime, loadNumberOfPresent, pushStartTime, setStarttime, refreshEventNumber]} />;
  }

  /*   const handleMenuClick = () => {
      console.log("Menu was clicked");
    }
   */


  return (
    <Container maxWidth="sm">
      <Grid container spacing={2} style={{ "marginBottom": "10px" }}>
        <Grid size={8} >
          <img src="BSF_Logo.svg" alt="BSF" style={{ "maxHeight": "3rem" }} onClick={() => navigate("/")} />
        </Grid>

        <Grid container size={4} justifyContent="flex-end" alignItems="center" gap="20%">
          {isNetworkError && <WarningIcon/>}

          <Link to="eventlist">
            <Badge badgeContent={numberOfOpenEvents} color="success">
              <EventIcon color="action" />
            </Badge>
          </Link>

          {numberOfPresent > 0 &&
            <Link to="current">
              <Badge badgeContent={numberOfPresent} color="success">
                <PeopleIcon color="action" />
              </Badge>
            </Link>
          }
          <MenuOpenIcon onClick={handleMenuClick} />
        </Grid>
      </Grid>
      {output}
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={() => { handleMenuClose(); navigate("/"); }}>Zur Erfassung</MenuItem>
        {/* <MenuItem onClick={() => { pushStartTime(new Date()); setAnchorEl(null); }}>Anwesenheit teilen</MenuItem> */}

        {isEventAdmin(token, props.clientConfig) &&
          <MenuItem onClick={() => { navigate("/editEvent"); setAnchorEl(null); }}>Neues Event</MenuItem>
        }
        {isEventAdmin(token, props.clientConfig) &&
          <MenuItem onClick={() => { navigate("/editSeriesEvent"); setAnchorEl(null); }}>Neues Serienevent</MenuItem>
        }

        <MenuItem onClick={resetLogin}>Logout</MenuItem>
      </Menu>
    </Container >


  )
};

export default Layout;